export const sections = {
    platform: {
        text: `We are changing the way we own digital books, comics, and audiobooks`
    },
    neverEver: {
        text: `As bookworms, we've always wanted to easily share our ebooks with friends but find that we can't`
    },
    problem: {
        text: `
        We can't do what we want with our ebooks because most of the digital content we buy we don't really own: we buy a license to it
        <br>
        Mint Print is solving this digital ownership problem
        `
    },
    possibilities: {
        text: `
        <div>With our platform, there are brand new kinds of content that creators and authors can explore…</div>
        <br>
        <div>Imagine</div>
        <ul class="side-by-side">
            <li><span class="heading">Digital Mediums</span><br>Audiobooks with professional voice actors acting out lines vs one monotone reader</li>
            <li><span class="heading">Digital Collector's Editions</span><br>Specially illustrated comics where only 500 copies exist</li>
            <li><span class="heading">Digital Author Signatures</span><br>Authors can sign ebooks, audiobooks, and more at signing events</li>
        </ul>
    `
    },
    supporting: {
        text: `
        Other providers take advantage of creators on their platforms through predatory royalty rates
        <br>
        <span class="infographic-1"></span>
        <br>
        Mint Print is different, we are truly supporting creators by being <b>transparent</b>
        <ul class="side-by-side">
            <li><span class="heading">Better Royalties</span><br>Fair and transparent royalty rates</li>
            <li><span class="heading">Instant Payouts</span><br>Creators can instantly payout funds to their account at any time</li>
            <li><span class="heading">Direct Payments</span><br>Fans can directly send additional funds to creators</li>
        </ul>
        `
    }
}