import { render, staticRenderFns } from "./SignUpView.vue?vue&type=template&id=2ec8f7c3&scoped=true&"
import script from "./SignUpView.vue?vue&type=script&lang=ts&"
export * from "./SignUpView.vue?vue&type=script&lang=ts&"
import style0 from "./SignUpView.vue?vue&type=style&index=0&id=2ec8f7c3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ec8f7c3",
  null
  
)

export default component.exports