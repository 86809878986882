export const bios = {
    emily: {
        text: `Emily is one of the co-founders and is responsible for the daily operations and technical implementations of the platform, leading the development efforts of Mint Print. They are currently pursuing their Master of Science in Computer Science at Towson University. Emily is a full-time software architect with multiple years of industry expierence.`
    },
    mike: {
        text: `Michael is one of the co-founders and is responsible for the procurement of authors and publishers for Mint Print. Michael is a graduate of Towson University having earned his B.S. Information Systems and a M.S. Computer Science. Aside from running the company with Emily, Michael is a Product Manager for a non-profit cyber security education company also based in Towson.`
    },
    eilean: {
        text: ``
    },
    jal: {
        text: ``
    }
}