import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
// import querystring from 'querystring';

Vue.use(Vuex)

const API_URL = process.env.VUE_APP_API_URL;

export default new Vuex.Store({
  state: {
    subscribed: false,
    email: '',
    navVisible: true,
  },
  getters: {
  },
  mutations: {
    setSubscribed(state, value) {
      state.subscribed = value;
    },
    setEmail(state, value) {
      state.email = value;
    },
    setNavVisibility(state, value) {
      state.navVisible = value;
    },
  },
  actions: {
    async subscribe({ commit }, payload: { email: string },) {
      try {
        await axios.post(`${API_URL}/subscribe`, payload);
        commit('setSubscribed', true);
        commit('setEmail', payload.email);
      } catch (e) {
        console.error(e);
      }
    },
  },
  modules: {
  }
})
